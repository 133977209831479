import { TRANSACTIONS_PARAMETERS } from '../../constants';
import getContainFilterValue from '../getContainFilterValue';
import dayjs from 'dayjs';

export const getTransactionsFilters = (filters: any) => {
    let equalFilters = [];
    let containFilters = [];
    for (let key in filters) {
        if (filters[key] !== null) {
            switch (key) {
                case TRANSACTIONS_PARAMETERS.ID:
                case TRANSACTIONS_PARAMETERS.MERCHANT_ID:
                case TRANSACTIONS_PARAMETERS.MERCHANT_NAME.join('/'):
                case TRANSACTIONS_PARAMETERS.MERCHANT_AGREGATOR.join('/'):
                case TRANSACTIONS_PARAMETERS.MERCHANT_WEBSITE.join('/'):
                case TRANSACTIONS_PARAMETERS.MERCHANT_MCC.join('/'):
                case TRANSACTIONS_PARAMETERS.TRANSACTION_ID:
                case TRANSACTIONS_PARAMETERS.TRANSACTION_TERMINAL_ID:
                case TRANSACTIONS_PARAMETERS.CARD_HOLDER:
                case TRANSACTIONS_PARAMETERS.PLATFORM:
                case TRANSACTIONS_PARAMETERS.PAYMENT_TYPE:
                case TRANSACTIONS_PARAMETERS.MERCHANT_SUM:
                case TRANSACTIONS_PARAMETERS.RECIPIENT_WALLET_ID:
                case TRANSACTIONS_PARAMETERS.RECIPIENT_WALLET_USER_NAME:
                case TRANSACTIONS_PARAMETERS.SENDER_CARD_CRYPTOGRAM:
                case TRANSACTIONS_PARAMETERS.SENDER_CARD_ECI_INDICATOR:
                case TRANSACTIONS_PARAMETERS.SENDER_CARD_EXP_MON:
                case TRANSACTIONS_PARAMETERS.SENDER_CARD_EXP_YEAR:
                case TRANSACTIONS_PARAMETERS.SENDER_CARD_GATEWAY:
                case TRANSACTIONS_PARAMETERS.SENDER_CARD_PAN:
                case TRANSACTIONS_PARAMETERS.TRANSFER_SUM:
                case TRANSACTIONS_PARAMETERS.ORDER_ID:
                case TRANSACTIONS_PARAMETERS.EXTERNAL_TRANSACTION_ID:
                case TRANSACTIONS_PARAMETERS.REJECT_CODE:
                case TRANSACTIONS_PARAMETERS.REJECT_MESSAGE:
                case TRANSACTIONS_PARAMETERS.WITHDRAW_TYPE:
                    containFilters.push({
                        or: filters[key].map((item: string) => ({
                            [`tolower(${key})`]: getContainFilterValue(item),
                        })),
                    });
                    break;
                case TRANSACTIONS_PARAMETERS.CARD_NUMBER:
                case TRANSACTIONS_PARAMETERS.CLIENT_ID:
                case TRANSACTIONS_PARAMETERS.IP:
                case TRANSACTIONS_PARAMETERS.FINGER_PRINT:
                    const items = filters[key]?.[0]?.split(',') || [];
                    containFilters.push({
                        or: items.map((item: string) => ({
                            [`tolower(${key})`]: getContainFilterValue(item),
                        })),
                    });
                    break;
                case TRANSACTIONS_PARAMETERS.AMOUNT:
                    if (filters[key][0] || filters[key][1]) {
                        containFilters.push({
                            [key]: {
                                gt: isNaN(filters[key][0]) ? undefined : filters[key][0] * 100 - 1,
                                lt: isNaN(filters[key][1]) ? undefined : filters[key][1] * 100 + 1,
                            },
                        });
                    }
                    break;
                case TRANSACTIONS_PARAMETERS.TRANSACTION_DATE:
                    equalFilters.push({
                        [key]: {
                            ge: {
                                type: 'raw',
                                value: dayjs(filters[key][0]).startOf('day').toISOString(),
                            },
                            le: {
                                type: 'raw',
                                value: dayjs(filters[key][1]).endOf('day').toISOString(),
                            },
                        },
                    });
                    break;
                case TRANSACTIONS_PARAMETERS.STATUS:
                case TRANSACTIONS_PARAMETERS.VALIDATED:
                    equalFilters.push({
                        or: filters[key].map((item: string) => ({
                            [key]: `${item}`,
                        })),
                    });
                    break;
                default:
                    break;
            }
        }
    }
    return [...containFilters, ...equalFilters];
};
